@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

/* For Mobile */
@media screen and (max-width: 540px) {
  .card {
      width: 23rem !important;
  }
}

body {
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

.card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-weight: 500;
  color: #444;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.form-label {
  font-weight: 500;
  color: #555;
}

.form-control:focus {
  box-shadow: none;
  border-color: #28a745;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #23923d;
  border-color: #23923d;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.errorMessage {
    color: red;
    font-size: small;
    text-align: center;
}

