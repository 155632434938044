.nav-link {
    color: #4d525a !important;
}

.nav-item:hover {
    background-color: #21212b;
    border-radius: 5px;
}

.activeLink {
    color: #c4c4c6 !important;
}

.sidebar {
    background-color: #181820;
}

.dropdown-menu {
    position: absolute; 
    inset: auto auto 0px 0px; 
    margin: 0px; 
    transform: translate3d(-24px, -56px, 0px);
    background-color: #181820 !important;
}