.error-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .error-page-content {
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 1;
  }
  
  .error-page-heading {
    font-size: 100px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .error-page-subheading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .error-page-text {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .error-page-btn {
    font-size: 18px;
    padding: 10px 30px;
    background-color: #2c3e50;
    border: none;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .error-page-btn:hover {
    background-color: #233140;
  }
  
  .error-page-background {
    background-color: #f8f9fa;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
